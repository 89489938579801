<template>
  <div class="Rank">
    <vue-particles class="login-bg" color="#39AFFD" :particleOpacity="0.7" :particlesNumber="100" shapeType="circle"
      :particleSize="4" linesColor="#8DD1FE" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4" :linesDistance="150"
      :moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push">
    </vue-particles>
    <Card class="rankInfo" plain>
      <div class="title">
        <div class="row">
          <div class="col">
            <h5>名次</h5>
          </div>
          <div class="col">
            <h5>队名</h5>
          </div>
          <!-- <div class="col">
            <h5>初赛成绩</h5>
          </div> -->
          <div class="col">
            <h5>复赛成绩</h5>
          </div>
        </div>
        <div class="raw">
          <div class="tabel-line"></div>
        </div>
      </div>
      <div class="Rank-list">
        <div v-for="item in curInfo" :key="index">
          <div class="row">
            <div class="col">
              <h5>{{ item.rank }}</h5>
            </div>
            <div class="col">
              <h5>{{ item.name }}</h5>
            </div>
            <div class="col">
              <h5>{{ item.score }}</h5>
            </div>
            <!-- <div class="col">
              <h5>{{ item.rematch }}</h5>
            </div> -->
          </div>
          <div class="row">
            <div class="table-line"></div>
          </div>
        </div>
      </div>
      <div class="change-page">
        <pager :totalPage="totalPage" :index="index" @changeIndex="changeIndex" v-if="initPager">
        </pager>
      </div>
    </Card>
  </div>
</template>

<script>
import pager from '../components/Pager.vue'
import request from '@/apis/request'
import { Card } from '@/components'

export default {
  name: 'introduction',
  components: {
    pager,
    Card,
  },
  data() {
    return {
      Info: [],
      curInfo: [],
      index: 1,
      pageSize: 8,
      initPager: true,
      totalPage: 1,
    }
  },
  methods: {
    changeIndex(index) {
      this.index = index
      this.curInfo = this.Info.slice(
        (index - 1) * this.pageSize,
        index * this.pageSize
      )
    },
    getFakeData() {
      for (let i = 1;i < 10;i++) {
        this.Info.push({
          rank: i,
          name: '队伍' + i,
          captain: '队长' + i,
          score: 123 - i,
        })
      }
    },
    getRealData() {
      request
        .get('/team/rank')
        .then((res) => {
          console.log(res)
          if (res.code !== 200 || res.data.length === 0) {
            this.$alert('暂无排行榜！')
          } else {
            this.Info = res.data
            // rank; name; score; rematch;

            // 处理同分排名
            // let rank = 1
            // let sameScore = 1
            // let lastScore = this.Info[0].score
            // this.Info[0].rank = rank
            // for (let i = 1;i < this.Info.length;i++) {
            //   if (this.Info[i].score === lastScore) {
            //     this.Info[i].rank = rank
            //     sameScore++
            //   } else {
            //     rank += sameScore
            //     sameScore = 1
            //     this.Info[i].rank = rank
            //     lastScore = this.Info[i].score
            //   }
            // }

            console.log(this.Info)
            this.totalPage = Math.ceil(this.Info.length / this.pageSize)
            this.curInfo = this.Info.slice(
              (this.index - 1) * this.pageSize,
              this.index * this.pageSize
            )
          }
        })
        .catch((err) => {
          this.$alert('error')
          console.log(err)
        })
    },
    init() {
      this.getRealData()
    },
  },
  created() {
    this.init()
  },
}
</script>

<style scoped>
.Rank {
  font-weight: bold;
  color: #ffffff;
  /* text-align: center; */
  padding-top: 8vh;
  background-image: url('https://seed-cup.oss-cn-hangzhou.aliyuncs.com/seedcup/img/2022seedcup.png');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.title {
  font-size: 24px;
}

.Rank-list {
  font-size: 15px;
  margin-bottom: 1vh;
  min-height: 65vh;
  text-align: center;
}

.table-line {
  position: relative;
  margin: 0 auto;
  width: 90%;
  height: 1px;
  background-color: #d4d4d4;
  text-align: center;
  color: rgba(101, 101, 101, 1);
}

.change-page {
  width: 100%;
  font-size: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: auto;
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
  min-height: 3vh;
  text-align: center;
}

.rankInfo {
  padding-left: 10%;
  padding-right: 10%;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: calc(100% - 100px);
}
</style>
